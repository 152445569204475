import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CampaginRules from "../components/campaginRules";
import "../App.css";
import info from "../competitionVariables";

function Footer() {
  return (
    <Grid container direction={"column"} alignItems="center" paddingBottom={2}>
      <Grid item>
        <CampaginRules />
      </Grid>
      <Grid item>
        <Typography variant="body1" color="primary">
          Vinneren trekkes: {info.endDate}.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
