import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function HeaderText() {
  return (
    <Grid item container direction={"column"}>
      <Grid
        item
        container
        direction={"row"}
        spacing={1}
        justifyContent="flex-start">
        <Grid item>
          <Typography variant="h5" color="primary">
            Ta bilde
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="secondary" className="outlinedText">
            av din
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="primary">
            pantelapp.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        spacing={1}
        justifyContent="flex-start">
        <Grid item>
          <Typography variant="h5" color="secondary" className="outlinedText">
            Bli med i
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="primary">
            trekningen
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="secondary" className="outlinedText">
            av
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderText;
