import {
  Container,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { BackArrow } from "../assets/BackArrow";

import Header from "../compositions/header";
import theme from "../theme/mainTheme";

function Personvern() {
  const navigate = useNavigate();

  //Goes back 1 page in history
  const handleOnClick = useCallback(() => navigate(-1), [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth="md" className="container">
        <Header />
        <Container disableGutters maxWidth="sm" className="container">
          <Grid container direction={"column"}>
            <Grid
              container
              xs={12}
              item
              alignItems={"center"}
              direction={"row"}>
              <Grid item xs={5}>
                <IconButton aria-label="back" onClick={handleOnClick}>
                  <BackArrow />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              padding={2}
              direction={"column"}
              alignItems="flex-start"
              justifyContent={"center"}>
              <Grid item>
                <Typography variant="h4" color="primary">
                  PERSONVERN
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color={"primary"}>
                  Hos Unibottle AS er vi opptatt av å beskytte din personvern.
                  Denne personvernerklæringen forklarer hvordan vi samler inn,
                  bruker og deler informasjon når du besøker vår nettside{" "}
                  <a href="https://www.unibottle.no">
                    https://www.unibottle.no.
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color={"primary"}>
                  Når du interagerer med vår nettside, kan vi samle inn visse
                  opplysninger gjennom bruk av sporingstegn og
                  informasjonskapsler. Disse teknologiene gir oss mulighet til å
                  overvåke nettstedstrafikk og brukeratferd gjennom Google
                  Analytics. Informasjonen vi samler inn inkluderer din
                  nettleservariant og omtrentlig plassering (basert på
                  IP-adressen din) så vel som dine nettleseringhandlinger på vår
                  nettside. Vi bruker denne informasjonen kun til interne
                  informasjons- og statistikkformål, og deler den ikke med
                  tredjeparter.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color={"primary"}>
                  Hvis du har spørsmål eller bekymringer rundt vår bruk av
                  informasjonskapsler eller andre teknologier, kan du gjerne
                  kontakte oss på kontakt@unibottle.no. Du kan også nå oss per
                  post på følgende adresse:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color={"primary"}>
                  Unibottle AS, Abbedikollen 26B 0280, Oslo, Norge
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color={"primary"}>
                  Vi tar personvernet av dine personlige opplysninger på alvor,
                  og er forpliktet til å sikre at ditt personvern respekteres
                  til alle tider. Takk for at du velger å bruke vår nettside.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default Personvern;
