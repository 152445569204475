import Container from "@mui/material/Container";
import Header from "../compositions/header";
import "../App.css";
import Grid from "@mui/material/Grid";
import { IconButton, ThemeProvider, Typography } from "@mui/material";

import theme from "../theme/mainTheme";
import RegisterBtn from "../components/RegisterBtn";
import { OneLeft } from "../assets/OneLeft";
import { OneRight } from "../assets/OneRight";
import { TwoLeft } from "../assets/TwoLeft";
import { TwoRight } from "../assets/TwoRight";

import { Link, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";

function CampagainInfoPage() {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => navigate(-1), [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth="md" className="container">
        <Header />
        <Container disableGutters maxWidth="sm">
          <Grid
            container
            justifyContent={"flex-end"}
            direction={"row"}
            padding={2}>
            <Grid item>
              <IconButton onClick={handleOnClick}>
                <CloseIcon color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"column"}
            alignItems="center"
            justifyContent={"center"}
            spacing={5}>
            <Grid item>
              <Typography variant="h3" color="primary">
                Hva gjør jeg?
              </Typography>
            </Grid>
            <Grid
              spacing={1}
              wrap="nowrap"
              item
              container
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}>
              <Grid item>
                <OneLeft />
              </Grid>
              <Grid item>
                <Typography textAlign={"center"} variant="h5" color={"primary"}>
                  Registrer e-post og postnummer.
                </Typography>
              </Grid>
              <Grid item>
                <OneRight />
              </Grid>
            </Grid>
            <Grid
              wrap="nowrap"
              spacing={1}
              item
              container
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}>
              <Grid item>
                <TwoLeft />
              </Grid>
              <Grid item>
                <Typography textAlign={"center"} variant="h5" color="primary">
                  Ta bilde av pante-kvittering.
                </Typography>
              </Grid>
              <Grid item>
                <TwoRight />
              </Grid>
            </Grid>
            <Grid item>
              <RegisterBtn disable={false} />
            </Grid>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
              item>
              <Grid item>
                <Typography variant="body1" color="primary">
                  Vilkår for konkurranse.
                </Typography>
              </Grid>
              <Grid item>
                <Link to="/comp-consent">
                  <Typography variant="body1" color="primary">
                    Les mer.
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default CampagainInfoPage;
