import {
  Button,
  Grid,
  ThemeProvider,
  Typography,
  Dialog,
  Alert,
} from "@mui/material";

import LoadingOverlay from "react-loading-overlay-ts";

import { TextField, Checkboxes } from "mui-rff";

import Container from "@mui/material/Container";
import Header from "../compositions/header";
import fieldTheme from "../theme/inputFieldTheme";
import theme from "../theme/mainTheme";
import styled from "styled-components";

import { AppService } from "../services/app.service";

import defaultImage from "../assets/defaultImage.png";

import { CameraAlt } from "@mui/icons-material";

import "../App.css";

import { Form } from "react-final-form";

import { useCallback, useState } from "react";
import mainTheme from "../theme/mainTheme";
import CameraView from "../components/CameraView";
import RequestBody from "../types/request";

import { Link, useNavigate } from "react-router-dom";

const ImagePreview = styled.div<{ image: string | null }>`
  width: 180px;
  height: 238px;
  ${({ image }) =>
    image
      ? `background-image:  url(${image});`
      : `background-image:  url(${defaultImage});`}
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid;
  border-color: #013a65;
  border-radius: 33px;
  background-position: center;
  @media (max-width: 400px) {
    width: 160px;
    height: 210px;
  }
`;

function RegisterPage() {
  const [image, setImage] = useState<string | null>(null);
  // const [submittedValues, setSubmittedValues] = useState<RequestBody | null>(
  //   null
  // );
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const envUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const handleSuccess = useCallback(() => {
    navigate("/succsess-page");
  }, [navigate]);
  const handleError = useCallback(() => {
    navigate("/error-page");
  }, [navigate]);

  const appService = new AppService();

  const sendTicket = async (values: RequestBody) => {
    if (values !== null && envUrl !== undefined) {
      setLoading(true);
      await appService.addTicket(values).then((data) => {
        if (data["status"] === 200) {
          setLoading(false);
          handleSuccess();
        } else {
          setLoading(false);
          handleError();
        }
      });
    } else {
      handleError();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateImage = (image: string): void => {
    setImage(image);
    setOpen(false);
    setAlert(false);
  };

  const onSubmit = (values: RequestBody) => {
    if (image === null) {
      setAlert(true);
    } else {
      //setSubmittedValues(values);
      sendTicket(values);
    }
  };

  const onlyNumbers = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  // const initialValues = useMemo(() => (), []);

  return (
    <LoadingOverlay active={loading} spinner text="Behandler påmelding...">
      <Container disableGutters className="container" maxWidth="md">
        <Header />
        <Container disableGutters maxWidth="sm">
          <ThemeProvider theme={theme}>
            <Form
              keepDirtyOnReinitialize
              initialValues={{ receipt: image }}
              subscription={{
                submitting: true,
              }}
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, values, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    padding={2}
                    direction="column"
                    alignItems={"center"}
                    justifyContent="center">
                    <Grid
                      xs={12}
                      item
                      container
                      justifyContent={"center"}
                      direction={"column"}
                      alignItems="flex-start">
                      <ThemeProvider theme={fieldTheme}>
                        <Grid item>
                          <Typography variant="h6" color="primary">
                            Registrer.
                          </Typography>
                        </Grid>
                        <Grid item width={"80%"} minWidth="250px">
                          <TextField
                            label="E-post"
                            name="email"
                            required
                            fullWidth
                            type="email"
                            margin="normal"
                            size="small"
                          />
                        </Grid>
                        <Grid item width={"142px"}>
                          <TextField
                            label="Postnr"
                            name="pcode"
                            required
                            margin="normal"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            onInput={(e) => onlyNumbers(e)}
                          />
                        </Grid>
                      </ThemeProvider>
                      <ThemeProvider theme={mainTheme}>
                        <Grid
                          item
                          container
                          spacing={2}
                          marginTop={1}
                          alignItems="center"
                          justifyContent={"center"}
                          direction={"row"}
                          xs={12}>
                          <Grid
                            xs={6}
                            item
                            spacing={8}
                            padding={1}
                            container
                            alignItems="flex-start"
                            direction={"column"}>
                            <Grid item>
                              <Typography variant="h6" color={"primary"}>
                                Ta bilde av kvittering etter panting.
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Button
                                variant="outlined"
                                endIcon={<CameraAlt />}
                                onClick={handleClickOpen}>
                                {image ? "Nytt bilde" : "Ta bilde"}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={6}
                            justifyContent="flex-end"
                            width={40}>
                            <Grid item>
                              <ImagePreview image={image} />
                            </Grid>
                          </Grid>
                          <Grid item>
                            {alert ? (
                              <Alert variant="outlined" severity="error">
                                Bilde mangler - Du må laste opp et bilde av
                                gyldig kvittering for å kunne delta
                              </Alert>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          marginBottom={2}
                          item
                          container
                          direction={"row"}
                          justifyContent="center"
                          alignItems={"center"}>
                          <ThemeProvider theme={fieldTheme}>
                            <Grid item>
                              <Checkboxes
                                style={{ marginRight: 0 }}
                                name="consent"
                                required
                                data={{
                                  label: (
                                    <Typography
                                      variant="subtitle2"
                                      color={"primary"}>
                                      Jeg godtar vilkår for deltakelse*
                                    </Typography>
                                  ),
                                  value: true,
                                }}
                              />
                            </Grid>
                          </ThemeProvider>
                          <Grid item>
                            <Link to="/comp-consent">
                              <Typography variant="subtitle2" color="primary">
                                Les mer
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent={"center"}
                          alignItems={"center"}>
                          <Grid item>
                            <Button
                              disabled={submitting}
                              style={{
                                width: 302,
                                height: 57,
                                fontSize: 17,
                              }}
                              variant="contained"
                              type="submit"
                              size="large">
                              Ferdig
                            </Button>
                          </Grid>
                        </Grid>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </ThemeProvider>
        </Container>
      </Container>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <CameraView closeMainModal={handleClose} updateImage={updateImage} />
      </Dialog>
    </LoadingOverlay>
  );
}

export default RegisterPage;
