import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "@fontsource/inter";
import "./assets/fonts/neurialgrotesk.woff";

import App from "./App";
import RegisterPage from "./pages/RegisterPage";

import CampagainInfoPage from "./pages/CampaginInfoPage";
import Personvern from "./pages/Personvern";
import SuccessPage from "./pages/SuccessPage";
import ErrorUploadPage from "./pages/ErrorUploadPage";
import ErrorPage from "./reactRouter/errorPage";
import KonkurranseInfo from "./pages/KonkurranseInfo";
import ScrollToTop from "./reactRouter/ScrollToTop";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path="/">
            <Route index element={<App />} />
            <Route path="register" element={<RegisterPage />} />
            <Route
              path="campigan-information"
              element={<CampagainInfoPage />}
            />
            <Route path="personvern" element={<Personvern />} />
            <Route path="succsess-page" element={<SuccessPage />} />
            <Route path="error-page" element={<ErrorUploadPage />} />
            <Route path="comp-consent" element={<KonkurranseInfo />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
);
