import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

interface IRegisterBtn {
  disable: boolean;
}

function RegisterBtn({ disable }: IRegisterBtn) {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    () => navigate("/register", { replace: true }),
    [navigate]
  );

  return (
    <Button
      style={{
        width: 302,
        height: 57,
        fontSize: 17,
      }}
      color="primary"
      size="large"
      disabled={true}
      variant="contained"
      onClick={handleOnClick}>
      Bli Med
    </Button>
  );
}
export default RegisterBtn;
