import "./App.css";
import Container from "@mui/material/Container";

import Grid from "@mui/material/Grid";
import Header from "./compositions/header";

//import period from "./competitionVariables";

import theme from "./theme/mainTheme";

import { Button, ThemeProvider } from "@mui/material";
import HeaderText from "./compositions/HeaderText";
import PrizeContainer from "./compositions/PrizeContainer";
import Footer from "./compositions/Footer";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function App() {
  // denne er en environment variabel som blir lagt til under publisering.
  // for å bruke denne lokalt, opprett en .env fil og legg den inn der
  //const apiURL = process.env.REACT_APP_API_URL;
  //const compPeriod = period;

  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth="md" className="container">
        <Header />
        <Container disableGutters maxWidth="sm" className="testContainer">
          <Grid
            item
            container
            padding={2}
            spacing={5}
            alignContent="center"
            justifyContent={"space-evenly"}
            direction={"column"}>
            <Grid item>
              <HeaderText />
            </Grid>
            <Grid item>
              <PrizeContainer buttonDisable={false} />
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </Grid>
          <CookieConsent
            contentStyle={{
              margin: 0,
              flex: "1 0 100px",
              padding: "0px 0px 0px 15px",
            }}
            style={{
              maxWidth: "600px",
              marginLeft: "2%",
              marginBottom: "15px",
              minWidth: "300px",
              backgroundColor: "#E4EDF0",
              width: "70%",
              border: "2px solid",
              borderColor: "#013A65",
              borderRadius: "66px",
              color: "#013A65",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
            }}
            ButtonComponent={Button}
            buttonText={"Godta"}
            buttonStyle={{
              padding: "10px 15px 10px 15px",
              border: "1px",

              borderRadius: "33px",
              backgroundColor: "#013A65",
              color: "#FEF7E7",
            }}>
            Godtar du våre cookies?{" "}
            <Link style={{ color: "#013A65" }} to="/personvern">
              Les mer
            </Link>
          </CookieConsent>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default App;
