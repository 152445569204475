export const WaveDivider = () => {
  return (
    <svg viewBox="0 0 428 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7C2.22143 7 4.91005 6.41601 7.48708 5.64719C13.7788 3.77014 20.4831 3.02444 26.7821 4.87708V4.87708C31.5019 6.26526 36.5137 6.32061 41.2631 5.03701L43.5693 4.41371C49.4233 2.83154 55.5872 2.79159 61.4613 4.29776L64.4802 5.07186C69.3928 6.33147 74.5616 6.17367 79.3881 4.61673L79.8096 4.48078C84.8134 2.86664 90.1944 2.842 95.2128 4.41026L96.6296 4.85299C101.084 6.24494 105.874 6.1208 110.25 4.5V4.5C114.626 2.8792 119.416 2.75506 123.87 4.14701L125.86 4.76876C130.533 6.22916 135.514 6.40141 140.277 5.26737L145.473 4.03031C151.063 2.69919 156.903 2.8293 162.428 4.40812L162.801 4.51461C168.49 6.1399 174.517 6.16206 180.217 4.57863L181.196 4.3068C186.613 2.80207 192.352 2.92002 197.703 4.64604V4.64604C202.484 6.18835 207.586 6.44981 212.5 5.40432L220.684 3.66289C225.808 2.5727 231.127 2.82083 236.127 4.38337V4.38337C241.598 6.09312 247.44 6.22624 252.984 4.76745L254.641 4.33121C260.446 2.80377 266.549 2.82403 272.343 4.38998L273.858 4.79959C279.176 6.23667 284.792 6.12735 290.05 4.48446V4.48446C295.245 2.86107 300.792 2.73468 306.055 4.11979L308.401 4.73715C314.055 6.22497 319.979 6.36623 325.697 5.14959L332.276 3.74977C337.676 2.60079 343.276 2.7931 348.584 4.30986L349.673 4.62084C355.112 6.17495 360.881 6.15259 366.308 4.55637L366.796 4.4128C372.145 2.83965 377.865 2.89545 383.187 4.55841V4.55841C388.274 6.1481 393.733 6.26139 398.878 4.87084V4.87084C405.705 3.02555 412.932 4.10066 419.784 5.85551C422.374 6.51907 425.216 7 428.011 7"
        stroke="#013A65"
        strokeWidth="4"
      />
    </svg>
  );
};
