import {
  Container,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../compositions/header";
import theme from "../theme/mainTheme";
import teknikker from "../assets/teknikker.png";
import "../App.css";
import CloseIcon from "@mui/icons-material/Close";
import ScrollToTop from "../reactRouter/ScrollToTop";

function ErrorUploadPage() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth="md" className="container">
          <Header />
          <Container disableGutters maxWidth="sm">
            <Grid
              container
              justifyContent={"flex-end"}
              direction={"row"}
              padding={2}>
              <Grid item>
                <IconButton onClick={handleOnClick}>
                  <CloseIcon color="primary" fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={4}
              alignItems="center"
              justifyContent={"center"}
              direction={"column"}>
              <Grid item paddingLeft={2} paddingRight={2}>
                <Typography color={"primary"} variant="h4" textAlign={"center"}>
                  Det ser ut som vi har noen tekniske problemer...
                </Typography>
              </Grid>
              <Grid item padding={2}>
                <Typography color={"primary"} variant="h4" textAlign={"center"}>
                  Teknikker er på saken 👨🏻‍🎨
                </Typography>
              </Grid>
              <Grid item container alignItems={"flex-start"}>
                <Grid item>
                  <img src={teknikker} alt="teknikker" className="teknikker" />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default ErrorUploadPage;
