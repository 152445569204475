import axios from "axios";
import RequestBody from "../types/request";

export class AppService {
  //  public async getUsers(): Promise<any> {
  //    const response = await axios.get("/api/users");
  //    return response.data;
  //  }

  public async addTicket(ticket: RequestBody): Promise<any> {
    let campaignUrl = "";
    if (process.env.REACT_APP_API_URL !== undefined) {
      campaignUrl = process.env.REACT_APP_API_URL;
    }
    const konverter = JSON.stringify(ticket);
    try {
      const response = await axios.post(
        campaignUrl.concat("/campaign"),
        konverter,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response;
    } catch (error) {
      //@ts-ignore
      console.log("Error: ", error["message"]);
      return false;
    }
  }
}
