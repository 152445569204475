export const OneRight = () => {
  return (
    <svg
      width="61"
      height="59"
      viewBox="0 0 61 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_603_1601)">
        <path
          d="M29.5 0.5H60.5V58.5H0.5V29.5C0.5 13.4837 13.4837 0.5 29.5 0.5Z"
          fill="#013A65"
          stroke="#E4EDF0"
        />
        <path
          d="M54.8429 45.9618C59.2515 49.7406 61.8107 54.7535 62.1001 59.9996H50.0957H37.0638L12.0276 59.9996C12.317 54.7535 14.8762 49.7406 19.2847 45.9618C23.9774 41.9395 30.371 39.6592 37.0638 39.6592C43.7567 39.6592 50.1502 41.9395 54.8429 45.9618Z"
          stroke="#FEF7E7"
          strokeWidth="2"
        />
        <path
          d="M48.2977 23.234C48.2977 29.4384 43.268 34.4681 37.0636 34.4681C30.8592 34.4681 25.8296 29.4384 25.8296 23.234C25.8296 17.0297 30.8592 12 37.0636 12C43.268 12 48.2977 17.0297 48.2977 23.234Z"
          stroke="#FEF7E7"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_603_1601">
          <rect width="61" height="59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
