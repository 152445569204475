export const PhotoBtn = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="28" fill="#D9D9D9" />
      <circle cx="40" cy="40" r="35.5" stroke="#D9D9D9" strokeWidth="9" />
    </svg>
  );
};
