import { useRef, useState } from "react";
import styled from "styled-components";

import { Camera, CameraType } from "react-camera-pro";
import {
  IconButton,
  ThemeProvider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { PhotoBtn } from "../assets/PhotoBtn";
import mainTheme from "../theme/mainTheme";

import PhotoLibraryRoundedIcon from "@mui/icons-material/PhotoLibraryRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 70%;
  background: rgba(0, 0, 0);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column-reverse;
  padding: 10px;
  bottom: 0;
  box-sizing: border-box;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;
const TopControl = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 30px;
  height: 30%;
  background: rgba(0, 0, 0);
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;

  @media (max-aspect-ratio: 1/1) {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    bottom: 1;
    width: 100%;
    height: 10%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const ImagePreview = styled.div<{ image: string | null }>`
  width: auto;
  height: 300px;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 400px) {
    width: auto;
    height: 250px;
  }
`;

interface ICameraView {
  updateImage: (arg: string) => void;
  closeMainModal: () => void;
}

const CameraView = ({ closeMainModal, updateImage }: ICameraView) => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState<string | null>(null);
  const camera = useRef<CameraType>(null);
  //const [file, setFile] = useState<string>();
  const [imagePreview, setImagePreview] = useState<any>("");

  const [open, setOpen] = useState(false);

  const handleUpload = () => {
    if (image !== null) {
      updateImage(image);
    } else if (imagePreview !== null) {
      updateImage(imagePreview);
    }
  };

  const handleClose = () => {
    setImage(null);
    setImagePreview(null);
    setOpen(false);
  };

  const photoUpload = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    //console.log("reader", reader);
    //console.log("file", file);

    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        //setFile(file);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setOpen(true);
    }
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Wrapper>
        <TopControl>
          <IconButton
            color="secondary"
            aria-label="upload picture"
            component="label">
            <input
              hidden
              accept=".jpeg, .png, .jpg"
              type="file"
              onChange={photoUpload}
            />
            <PhotoLibraryRoundedIcon color="inherit" fontSize="inherit" />
          </IconButton>
        </TopControl>
        <Camera
          ref={camera}
          facingMode="environment"
          aspectRatio="cover"
          numberOfCamerasCallback={setNumberOfCameras}
          errorMessages={{
            noCameraAccessible:
              "No camera device accessible. Please connect your camera or try a different browser.",
            permissionDenied:
              "Permission denied. Please refresh and give camera permission.",
            switchCamera:
              "It is not possible to switch camera to different one because there is only one video device accessible.",
            canvas: "Canvas is not supported.",
          }}
        />
        <Control>
          <Button variant="text" color="secondary" onClick={closeMainModal}>
            avbryt
          </Button>
          <IconButton
            aria-label="delete"
            size="large"
            color="secondary"
            onClick={() => {
              if (camera.current) {
                const photo = camera.current.takePhoto();
                setImage(photo);
                setImagePreview(photo);
                setOpen(true);
              }
            }}>
            <PhotoBtn />
          </IconButton>

          <IconButton
            size="large"
            color="secondary"
            disabled={numberOfCameras <= 1}
            onClick={() => {
              if (camera.current) {
                const result = camera.current.switchCamera();
                console.log(result);
              }
            }}>
            <CachedRoundedIcon
              color={numberOfCameras <= 1 ? "disabled" : "secondary"}
              fontSize="inherit"
            />
          </IconButton>
        </Control>
      </Wrapper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ textAlign: "center" }}>
          {"Vil du laste opp dette bildet?"}
        </DialogTitle>
        <DialogContent>
          <ImagePreview image={imagePreview} />
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Button
            variant="outlined"
            style={{ marginRight: "30px", marginBottom: "20px" }}
            onClick={handleClose}>
            Forkast
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "30px", marginBottom: "20px" }}
            onClick={handleUpload}
            autoFocus>
            Last opp
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CameraView;
