import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "neurialgrotesk-extrabold",
    h3: {
      fontSize: "2.375rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    body1: {
      fontFamily: "Inter",
      fontWeight: "bold",
      letterSpacing: "1%",
      lineHeight: 2,
    },
    body2: {
      fontSize: "1rem",
      lineHeight: 2,
    },
  },
  palette: {
    primary: {
      main: "#013A65",
      contrastText: "#FEF7E7",
    },
    secondary: {
      main: "#FEF7E7",
      contrastText: "#013A65",
    },
    info: {
      main: "#E4EDF0",
      contrastText: "#013A65",
    },
    text: {
      primary: "#013A65",
      secondary: "#FEF7E7",
    },
    background: {
      paper: "#FEF7E7",
      default: "#FEF7E7",
    },
  },
  shape: {
    //This this is for all components exept input fields which req. radius 7
    borderRadius: 33,
  },
});

export default theme;
