export const TwoRight = () => {
  return (
    <svg
      width="61"
      height="59"
      viewBox="0 0 61 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5 0.5H60.5V58.5H0.5V29.5C0.5 13.4837 13.4837 0.5 29.5 0.5Z"
        fill="#013A65"
        stroke="#E4EDF0"
      />
      <path
        d="M31.4303 17.928C32.585 15.928 35.4718 15.928 36.6265 17.928L40.3518 24.3804C41.5065 26.3804 40.0631 28.8804 37.7537 28.8804H30.3031C27.9937 28.8804 26.5503 26.3804 27.705 24.3804L31.4303 17.928Z"
        fill="#FEF7E7"
      />
      <rect
        x="24.6641"
        y="9.6814"
        width="18.7302"
        height="11.2381"
        fill="#013A65"
      />
      <rect
        x="19.0437"
        y="23.7292"
        width="29.9683"
        height="23.4127"
        rx="4"
        fill="#FEF7E7"
      />
      <circle cx="34.4959" cy="35.4379" r="7.02381" fill="#013A65" />
      <circle cx="34.4943" cy="35.4379" r="5.15079" fill="#FEF7E7" />
      <circle cx="44.3321" cy="29.3488" r="1.87302" fill="#013A65" />
    </svg>
  );
};
