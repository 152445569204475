import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import info from "../competitionVariables";
import { Meny } from "../assets/Meny";

function PrizeWindow() {
  return (
    <Grid
      item
      container
      direction={"column"}
      alignItems={"center"}
      justify-content="center"
      spacing={3}>
      <Grid item>
        <Typography variant="h5" color="primary">
          {info.prize}
        </Typography>
      </Grid>
      <Grid item>
        <Meny />
      </Grid>
    </Grid>
  );
}
export default PrizeWindow;
