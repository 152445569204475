import {
  Container,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../compositions/header";
import theme from "../theme/mainTheme";
import CloseIcon from "@mui/icons-material/Close";
import ScrollToTop from "../reactRouter/ScrollToTop";

function SuccessPage() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth="md" className="container">
          <Header />
          <Container disableGutters maxWidth="sm">
            <Grid
              container
              justifyContent={"flex-end"}
              direction={"row"}
              padding={2}>
              <Grid item>
                <IconButton onClick={handleOnClick}>
                  <CloseIcon color="primary" fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              marginTop={1}
              item
              container
              padding={3}
              spacing={5}
              alignItems="center"
              justifyContent={"center"}
              textAlign="center"
              direction={"column"}>
              <Grid item marginBottom={2}>
                <Typography variant="h1">✅</Typography>
              </Grid>
              <Grid item>
                <Typography color={"primary"} variant="h4">
                  Supert, vi har motatt din registrering ♻️
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default SuccessPage;
