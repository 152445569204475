import {
  Container,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";

import { BackArrow } from "../assets/BackArrow";

import Header from "../compositions/header";
import theme from "../theme/mainTheme";
import info from "../competitionVariables";

function KonkurranseInfo() {
  const navigate = useNavigate();

  //Goes back 1 page in history
  const handleOnClick = useCallback(() => navigate(-1), [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth="md" className="container">
        <Header />
        <Container disableGutters maxWidth="sm" className="container">
          <Grid container direction={"column"}>
            <Grid
              container
              xs={12}
              item
              alignItems={"center"}
              direction={"row"}>
              <Grid item xs={2}>
                <IconButton aria-label="back" onClick={handleOnClick}>
                  <BackArrow />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              padding={2}
              direction={"column"}
              alignItems="flex-start"
              justifyContent={"center"}>
              <Grid item>
                <Typography variant="h4" color="primary">
                  UNIBOTTLE KONKURRANSE, VILKÅR
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  ARRANGØR AV KONKURRANSEN
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  unibottle AS
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Abbedikollen 26B,
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  0280, Oslo
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  AKSEPT AV VILKÅR OG BETINGELSER
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Ved å delta i konkurransen aksepterer deltakeren
                  konkurransereglene.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  PREMIE{" "}
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Premien er {info.prize} til en verdi av {info.price}. Det
                  trekkes {info.numberOfWinners}, {info.selectionOccurency}, i
                  løpet av kampanjeperioden ({info.period}).
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Vinnerne trekkes av en representant fra Unibottle. En
                  representant fra Unibottle vil ta kontakt med vinnerne.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Premien må aksepteres slik som den er, og kan ikke byttes inn
                  mot kontanter. Unibottle er ikke ansvarlige for eventuelle
                  skader som måtte oppstå under transport av premien.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Vinner svarer for eventuell gevinstskatt.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  KAMPANJEPERIODE
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  {info.period}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  HVORDAN DELTA?
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  1. Registrer E-mail og postnummer.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  2. Ta bilde av pantekvittering (maks en pantekvittering per
                  dag).
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  SLIK BLI VINNEREN PLUKKET UT{" "}
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Vinnere av konkurransen trekkes tilfeldig, basert på
                  Registering av E-mail, postnummer og pantekvittering. Det
                  trekkes totalt {info.numberOfWinners}, som hver får (
                  {info.prize}). Beslutningen som blir tatt er endelig, og kan
                  ikke overklages. Vinner blir kontaktet via oppgitt
                  E-postadresse fra unibottle sin E-postadresse. Her vil vi
                  spørre om kontaktinfo og adresse for utsendelse av premie.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  DISKVALIFISERING
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Unibottle og andre partnere forbeholder seg retten til å
                  diskvalifisere deltakere som bryter med konkurransereglene,
                  deltar med samme pantekvittering eller forsøker å sabotere
                  konkurransen. Disse deltagerne vil også bli utestengt.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  FORBEHOLD
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Unibottle og andre partnere i denne konkurransen tar ikke
                  ansvar for eventuelle trykkfeil eller andre utilsiktete
                  misforståelser. Unibottle og andre partnere kan ikke holdes
                  ansvarlig for materialet som sendes inn av deltakerne i
                  konkurransen.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Hvis, av en eller annen grunn, kampanjen ikke kan bli
                  gjennomført som planlagt av grunner som er utenfor Unibotlle
                  sin kontroll, vil Unibottle reservere seg retten til å, når
                  som helst og uten forvarsel, avbryte, avslutte, endre eller
                  utsette hele eller deler av kampanjen.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  I den utstrekning loven tillater, vil arrangørene ikke være
                  ansvarlige for kampanjen eller premien, og tar ikke særlig
                  ansvar for deltakelse som er ugyldig på grunn av tekniske
                  problemer utenfor arrangørens kontroll eller problemer
                  angående tilgang til nettsiden.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Alle internettkostnader og andre kostnader som kan oppstå
                  forbundet med deltakelse i kampanjen, er utelukkende
                  deltakerens ansvar.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  PERSONOPPLYSNINGER
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Ved deltagelse i konkurransen kommer deltakeren til å måtte gi
                  fra seg visse personopplysninger, som navn og
                  kontaktinformasjon. Unibottle vil behandle personopplysninger
                  for å oppfylle sine forpliktelser vedrørende konkurransen,
                  blant annet for å kontakte deltakerne og dele ut premiene.
                  Vinner vil kunne annonseres på sosialemedier til Unibottle
                  (Instagram, Facebook, TikTok og Linkedin og unibottle.no),
                  presentert med fornavn og eventuelt by. Mottakelse av premie
                  forutsetter at vinneren samtykker til dette.
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Etter vi har fått sendt ut premien blir opplastet bilde
                  slettet. Kontaktinformasjon i form av epost og navn blir
                  lagret og vil kunne bli brukt til å sende ut nyheter etter
                  endt kampanjeperiode. Enhver deltakerkan etterspørre om å få
                  innsyn i sin kontaktinformasjon og melde seg avnyhetsbrev.
                  Dette kan gjøres ved å kontakte unibottle på
                  epost:kontakt@unibottle.no
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Ved å delta i konkurransen og akseptere disse vilkårene,
                  samtykker du til at Unibottle behandler og gir videre dine
                  personopplysninger til ovenfor beskrevne gjøremål.
                </Typography>
                <Typography variant="body1" color={"primary"}>
                  Du har rett til å tilbakekalle ditt samtykke til behandlingen
                  av personopplysninger. Tilbakekalles dette samtykket innen
                  vinnerne en kåret, anses du ikke lengre å oppfylle kravene for
                  å delta i konkurransen, og vi kommer ikke til å benytte dine
                  personopplysninger videre. Du har rett til å gratis, en gang
                  per kalenderår, etter skriftlig underskrevet søknad stilet til
                  Unibottle, å få beskjed om hvilke personopplysninger Unibottle
                  har om deg og hvordan disse behandles. Etter loven har du også
                  rett til å be om korrigering av eventuelle uriktige
                  personopplysninger Unibottle måtte ha.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color={"primary"}>
                  For mer informasjon om behandling av personopplysninger, se
                  vår personopplysningerside:
                </Typography>
                <Link to="/personvern">
                  <Typography variant="body1" color="primary">
                    Les mer.
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  KONTAKT{" "}
                </Typography>

                <Typography variant="body1" color={"primary"}>
                  Har du spørsmål om vilkårene eller konkurransen generelt, kan
                  du ta kontakt via mail:
                </Typography>
                <a href={"mailto:kontakt@unibottle.no"}>
                  <Typography variant="body1" color={"primary"}>
                    kontakt@unibottle.no
                  </Typography>
                </a>
              </Grid>
              <Grid item>
                <Typography variant="body1" color={"primary"}>
                  Denne konkurransen er på ingen måte arrangert eller
                  administrert av Instagram, Facebook, TikTok og Linkedin.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default KonkurranseInfo;
