import { createTheme } from "@mui/material";

const fieldTheme = createTheme({
  typography: {
    fontFamily: "neurialgrotesk-extrabold",
  },
  palette: {
    primary: {
      main: "#013A65",
      contrastText: "#FEF7E7",
    },
    secondary: {
      main: "#FEF7E7",
      contrastText: "#013A65",
    },
    info: {
      main: "#E4EDF0",
      contrastText: "#013A65",
    },
    text: {
      primary: "#013A65",
      secondary: "#013A65",
    },
    background: {
      paper: "#FEF7E7",
      default: "#FEF7E7",
    },
  },
  shape: {
    borderRadius: 7,
  },
});

export default fieldTheme;
