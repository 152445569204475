import {
    Container,
    Grid,
    IconButton,
    ThemeProvider,
    Typography,
  } from "@mui/material";
  import { useCallback } from "react";
  
  import { Link, useNavigate } from "react-router-dom";
  
  import { BackArrow } from "../assets/BackArrow";
  
  import Header from "../compositions/header";
  import theme from "../theme/mainTheme";
  import info from "../competitionVariables";
  
  function PrivacyPolicyPage() {
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate(-1), [navigate]);
  
    return (
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth="md" className="container">
          <Header />
          <Container disableGutters maxWidth="sm" className="container">
            <Grid container direction={"column"}>
              <Grid
                container
                xs={12}
                item
                alignItems={"center"}
                direction={"row"}>
                <Grid item xs={2}>
                  <IconButton aria-label="back" onClick={handleOnClick}>
                    <BackArrow />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                padding={2}
                direction={"column"}
                alignItems="flex-start"
                justifyContent={"center"}>
                <Grid item>
                  <Typography variant="h2" color="primary">
                    Personvernerklæring
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" color="primary">
                    Personvern hos Unibottle
                  </Typography>

                  <Typography variant="body1" color="primary">
                    <br></br>
                    Fundamentalt så er Unibottle et teknologiselskap som vil bruke allerede-eksisterende moderne teknolgi
                    til å gjøre det å resirkulere plast mye mer tilgjengelig og brukervennlig. 
                    For å kunne skreddersy løsningen til våre kunder, så trenger vi litt informasjon fra dem. 
                    Vi jobber hardt for å bygge en løsning som vi er stolte av, og som kan bidra til å forsterke den sirkulære verdikjeden.
                    Derfor har vi høyt fokus på å beskytte og respektere brukeren informasjon helt fra sekundet de lander på våre nettsider. 
                    <br></br>
                    <br></br>
                    Vi har ingenting å skjule. Derfor er det beskrevet under hva av informasjon vi lagrer, og hvorfor. 
                    Er det noen spørsmål rundt personvernserklæringen vår, ikke nøl med å ta kontakt. Kontaktinformasjon finnes på bunn av denne siden.
                    <br></br>
                    <br></br>
                    All vår innsamling og bruk av personopplysninger er underlagt relevante regler for beskyttelse av personopplysninger, inkludert EUs personvernforordning, også kjent som GDPR. 
                    Vi anser etterlevelse av disse forskriftene som et minimum, og vi vil alltid jobbe hardt for å overgå denne grunnlinjen når det gjelder etisk og fornuftig bruk av dataene dine.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" color="primary">
                    Datainnsamlig{" "}
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                    Når vi snakker om personopplysninger, mener vi informasjon som kan kobles til en enkeltperson. I sammenheng med denne erklæringen kan det inkludere navn, adresse, telefonnummer, e-postadresse osv.
                    Vi samler kun inn personopplysninger som blir oppgitt av brukeren selv. Dette skjer hovedsaklig under opprettelse av en brukerkonto hos oss. 
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color={"primary"}>
                    Du har ingen forpliktelse til å oppgi personopplysninger til oss, men hvis du velger å ikke gjøre det, kan du ikke ta i bruk våre tjenester.
                    Noen eksempler på dette kan være at vi ikke kan utbetale din saldo på konto hvis ikke vi vet ditt telefonnummer, 
                    og vi kan heller ikke vite hvilken panteboks vi skal veilede deg til hvis vi ikke vet din addresse.                 
                    </Typography>
                    <br></br>
                    <Typography variant="body1" color={"primary"}>
                    Selv om vi ber om en del personopplysninger ved opprettelse av bruker, er det ikke alt vi krever. 
                    Eksempler på informasjon som ikke kreves er kjønn, fødselsår og antall i husstand.            
                    </Typography>
                  <Typography variant="body1" color={"primary"}>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" color="primary">
                    Hva samler vi inn?
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                    <ul>
                        <li>Grunnleggende informasjon som navn, addresse og fødselsår samt måter å kontakte deg på, som for eksempel e-postadressen eller telefonnummeret ditt. </li>
                        <li>Logger og oversikt over alle pantinger du har gjort gjennom oss. Dette kan være antall panteposer som ble levert, hvor mange flasker det var i hver pose og hvor mye en pose var verdt i KR. Denne informasjonen blir også brukt til å opparbeide seg en saldo på en konto du har hos oss.</li>
                        <li>Informasjon som er basert på hvor lang tid du bruker på å gå igjennom en panteprosess. Dette er tidspunkt som kan hjelpe oss i å se om det er steg i panteprosessen vi kan forbedre. </li>
                        <li>Oversikt over alle uttak av konto du har gjort hos oss. Dette er hovedsaklig av sikkerhets-relaterte årsaker og for å ha kontroll på all pengeflyt i bedriften.</li>
                    </ul>
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color={"primary"}>
                  I tråd med våre bruksvilkår må du være over 18 år for å opprette en konto hos oss. Dette betyr at vi ikke behandler noen personopplysninger om barn. 
                  </Typography>
                </Grid>
                
                <Grid item>
                <Typography variant="h5" color="primary">
                    Hvorfor samler vi det inn?
                  </Typography>
  
                  <Typography variant="body1" color={"primary"}>
                    Vi bruker data registrert om deg til følgende formål:
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                  Vi behandler data for å tilby deg ulike tjenester. Dette betyr at vi har en avtale med deg om å behandle data i forbindelse med:
                    <ul>
                        <li>å tilby digitale tjenester gjennom en personlig brukerkonto</li>
                        <li>å tilby en brukervennlig og sikker tjeneste</li>
                        <li>feilrettinger, kundestøtte og klagebehandling</li>
                    </ul>
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                  Vi behandler data for å forstå behovene dine og forbedre og utvide tjenestene vi tilbyr. Det juridiske grunnlaget for dette er vår legitime interesse i å forbedre tjenestene våre, da vi vurderer konsekvensene av denne behandlingen til ikke å være vesentlig for deg. Vi begrenser personopplysningene som behandles, vurderer virkningen av behandlingen og innfører sikkerhetstiltak og andre tiltak for å begrense virkningen av slik behandling.<ul>
                        <li>innsamlig og analyse av data for å forstå bruksmønstre og behov for å forbedre og optimalisere tjenesten</li>
                        <li>bruk av statistiske data som grupperer brukere i lignende bruksmønstre, hvor lenge du har vært kunde, din generelle posisjon, aldersgruppe eller andre data som hjelper oss med å forstå hvordan folk som deg bruker tjenestene våre. I slike tilfeller samles og/eller anonymiseres dataene, slik at du ikke er personlig identifiserbar</li>
                        <li>for å kunne feilsøke og rette feil du måtte støte på</li>
                        <li>for å utarbeide brukerundersøkelser, brukeranalyser og markedsanalyser basert på bruksmønstre og demograf</li>
                    </ul>
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                    Direkte markedsføring:
                    <ul>
                        <li> Vi kan formidle tilbud, nyheter og informasjon om utvalget vårt samt endringer i funksjonalitet eller nyheter om tjenesten vår, eller annet som vi mener er relevant for deg. Dette kan komme til deg per e-post, telefon, tradisjonell post eller en varsling i appen. Det juridiske grunnlaget for dette er vår legitime interesse i å markedsføre tjenestene våre, da vi vurderer konsekvensene av denne behandlingen til ikke å være vesentlig for deg. Vi begrenser personopplysningene som behandles, vurderer virkningen av behandlingen og innfører sikkerhetstiltak og tiltak for å begrense virkningen av slik behandling</li>
                    </ul>
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                    Sikkerhet og forebygging av misbruk:
                    <ul>
                        <li> Vi kan behandle personopplysninger for å opprettholde sikkerheten i alle tjenestene våre, og for å oppdage eller forhindre ulike typer misbruk og svindel, som for eksempel hvitvasking av penger eller identitetstyveri. Det juridiske grunnlaget for dette er vår legitime interesse i å oppdage og forebygge svindel eller misbruk av tjenestene eller betalingsmetodene våre. Som tidligere vurderer vi konsekvensene av denne behandlingen til ikke å være vesentlig for deg. Vi begrenser alltid personopplysningene som behandles, vurderer virkningen av behandlingen og innfører sikkerhetstiltak og tiltak for å begrense virkningen av slik behandling.</li>
                    </ul>
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                    Overholdelse av lover og forskrifter:
                    <ul>
                        <li>Vi behandler personopplysninger for å overholde regnskapslover og lignende forskrifter, inkludert å presentere informasjon til myndigheter når det er pålagt av loven.</li>
                    </ul>
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    Slik lagrer og beskytter vi dataene dine{" "}
                  </Typography>
  
                  <Typography variant="body1" color={"primary"}>
                  Beskyttelse av dataene dine er en av våre øverste prioriteringer. 
                  Sikkerheten vår inkluderer både fysiske og tekniske tiltak, hvor mesteparten av tiltakene er tekniske tiltak, i og med at hele løsningen lever i skyen. 
                  Dette er tiltak som forhindrer at dataene dine går tapt eller havner i feil hender. Det blir gjort regelmessige vurderinger om vi kan gjøre endringer eller ta tekniske valg som kan gjøre løsningen tryggere. 
                  Eksempler på tiltak er å ta i bruk state-of-the-art sikkerhetsprotokoller, og omfattende tilgangsstyring på alle våre tjenester.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="primary">
                    Vi beholder personopplysningene dine så lenge du ikke ber oss om noe annet. Hvis du sletter din konto, blir all persondata du har hos oss slettet. 
                    All pantehistorikk du har knyttet til din private bruker vil vi fortsatt beholde, men hvem bruker de tilhører vil bli anonymisert. 
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="primary">
                  Ved avvik eller brudd på personopplysningssikkerheten vil vi varsle det relevante datatilsyn så snart vi kan, og i de fleste tilfeller senest 72 timer etter at vi blir oppmerksomme på det. Det eneste unntaket fra denne rutinen er dersom innbruddet trolig ikke vil føre til at våre kunders rettigheter og friheter settes i fare.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    Informasjonskapsler og sporing
                  </Typography>
  
                  <Typography variant="body1" color={"primary"}>
                    Som de fleste nettsider så bruker vi informasjonskapsler. Disse blir brukt til å kunne gi deg en god opplevelse og forbedre ytelsen i vår løsning. 
                    Vi bruker informasjonskapsler utelukkende av funksjonelle og tekniske grunner. 
                    Eksempler på dette er at du slipper å logge inn på nytt for hver gang du bruker applikasjonen vår, og generelt for å gi deg en god brukeropplevelse underveis. 
                    Per nå blir det ikke brukt informasjonskaplser til noe annet formål, som markedsføring og analyse av bruk av tjenesten. Dette kan komme i fremtiden, men dette vil du selvsagt bli varslet om i forkant.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    Dine rettigheter
                  </Typography>

                  <Typography variant="h5" color="primary">
                    Du har rett til å: 
                  </Typography>
                  <Typography variant="body1" color="primary">
                    <ul>
                        <li>Få vite hvilke opplysninger vi har om deg</li>
                        <li>Korrigere eventuelle uriktige opplysninger</li>
                        <li>Få tilsendt dataene dine i et lesbart format</li>
                        <li>Bli glemt</li>
                        <li>Sende inn en klage til ditt lokale datatilsyn</li>
                    </ul>
                  </Typography>
  
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    Spørsmål og henvendelser{" "}
                  </Typography>
  
                  <Typography variant="body1" color={"primary"}>
                    Har du spørsmål om denne personvernerklæringen eller om tjenesten generelt, kan
                    du ta kontakt via mail:
                  </Typography>
                  <a href={"mailto:kontakt@unibottle.no"}>
                    <Typography variant="body1" color={"primary"}>
                      kontakt@unibottle.no
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </ThemeProvider>
    );
  }
  
  export default PrivacyPolicyPage;
  