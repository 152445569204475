const period = "06.02.2023 - 12.02.2023";
const endDate = "12 februar 2023";
const prize = "Gavekort hos Meny";
const price = "500 NOK";
const selectionOccurency = "1 gang";
const numberOfWinners = "1 vinner";

// eslint-disable-next-line
export default {
  period,
  prize,
  price,
  selectionOccurency,
  numberOfWinners,
  endDate,
};
