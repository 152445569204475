import { useCallback } from "react";
import "../App.css";
import Grid from "@mui/material/Grid";
import { WaveDivider } from "../assets/WaveDivider";

import { BlueLogo } from "../assets/BlueLogo";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate("/"), [navigate]);

  return (
    <Grid container direction={"column"}>
      <Grid
        item
        marginTop={4}
        marginLeft={4}
        marginBottom={1}
        onClick={handleOnClick}>
        <BlueLogo />
      </Grid>
      <Grid item xs={12}>
        <WaveDivider />
      </Grid>
    </Grid>
  );
}

export default Header;
