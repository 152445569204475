export const OneLeft = () => {
  return (
    <svg
      width="66"
      height="59"
      viewBox="0 0 66 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 0.5H36.5C52.5163 0.5 65.5 13.4837 65.5 29.5V58.5H0.5V0.5Z"
        fill="#E4EDF0"
        stroke="#013A65"
      />
      <path
        d="M29.9361 12.5455V46H21.8665V20.0923H21.6705L14.1889 24.6662V17.6747L22.4382 12.5455H29.9361ZM41.1024 46.4737C39.9262 46.4737 38.9189 46.0599 38.0803 45.2322C37.2527 44.4046 36.8443 43.4027 36.8552 42.2266C36.8443 41.0722 37.2527 40.0866 38.0803 39.2699C38.9189 38.4422 39.9262 38.0284 41.1024 38.0284C42.2131 38.0284 43.1933 38.4422 44.0427 39.2699C44.903 40.0866 45.3386 41.0722 45.3495 42.2266C45.3386 43.0107 45.1317 43.724 44.7288 44.3665C44.3367 45.009 43.8194 45.5208 43.1769 45.902C42.5453 46.2831 41.8538 46.4737 41.1024 46.4737Z"
        fill="#013A65"
      />
    </svg>
  );
};
