import { useCallback } from "react";

import Typography from "@mui/material/Typography";
import { Grid, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CampaginRules() {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    () => navigate("/campigan-information"),
    [navigate]
  );

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems={"center"}>
      <Grid item>
        <Typography color="primary" variant="body1">
          Hvordan deltar jeg?{" "}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="primary" variant="body1">
          <Link onClick={handleOnClick}> {""}Les mer.</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
export default CampaginRules;
