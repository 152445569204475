import Grid from "@mui/material/Grid";
import PrizeWindow from "../components/prizeWindow";
import RegisterBtn from "../components/RegisterBtn";

interface IPriceContainer {
  buttonDisable: boolean;
}

function PrizeContainer({ buttonDisable }: IPriceContainer) {
  return (
    <Grid item container direction={"column"} alignItems="center" spacing={8}>
      <Grid item>
        <PrizeWindow />
      </Grid>
      <Grid item>
        <RegisterBtn disable={buttonDisable} />
      </Grid>
    </Grid>
  );
}

export default PrizeContainer;
