export const TwoLeft = () => {
  return (
    <svg
      width="66"
      height="59"
      viewBox="0 0 66 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 0.5H36.5C52.5163 0.5 65.5 13.4837 65.5 29.5V58.5H0.5V0.5Z"
        fill="#E4EDF0"
        stroke="#013A65"
      />
      <path
        d="M14.4666 46V40.1847L26.669 29.5014C27.5838 28.6738 28.3625 27.9169 29.005 27.2308C29.6475 26.5339 30.1375 25.8369 30.4751 25.1399C30.8127 24.4321 30.9815 23.6643 30.9815 22.8366C30.9815 21.911 30.7801 21.1214 30.3771 20.468C29.9742 19.8037 29.4188 19.2919 28.7109 18.9325C28.0031 18.5732 27.1918 18.3935 26.277 18.3935C25.3513 18.3935 24.54 18.584 23.843 18.9652C23.1461 19.3355 22.6016 19.8745 22.2095 20.5824C21.8284 21.2902 21.6378 22.1506 21.6378 23.1634H13.9766C13.9766 20.8873 14.4884 18.9216 15.5121 17.2663C16.5357 15.611 17.9732 14.3369 19.8246 13.4439C21.6868 12.54 23.8485 12.0881 26.3097 12.0881C28.8471 12.0881 31.0523 12.5128 32.9254 13.3622C34.7985 14.2116 36.2469 15.3987 37.2706 16.9233C38.3052 18.437 38.8224 20.1958 38.8224 22.1996C38.8224 23.4737 38.5665 24.737 38.0547 25.9893C37.5429 27.2417 36.6226 28.6248 35.294 30.1385C33.9763 31.6522 32.1032 33.4654 29.6747 35.5781L25.6726 39.2699V39.4822H39.2308V46H14.4666ZM49.2781 46.4737C48.102 46.4737 47.0947 46.0599 46.2561 45.2322C45.4285 44.4046 45.0201 43.4027 45.031 42.2266C45.0201 41.0722 45.4285 40.0866 46.2561 39.2699C47.0947 38.4422 48.102 38.0284 49.2781 38.0284C50.3889 38.0284 51.369 38.4422 52.2185 39.2699C53.0788 40.0866 53.5144 41.0722 53.5253 42.2266C53.5144 43.0107 53.3075 43.724 52.9046 44.3665C52.5125 45.009 51.9952 45.5208 51.3527 45.902C50.7211 46.2831 50.0296 46.4737 49.2781 46.4737Z"
        fill="#013A65"
      />
    </svg>
  );
};
